<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="dialogCouponList"
        max-width="10000px"
        scrollable
        persistent
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Danh sách coupon</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogCouponList = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <div class="table-responsive">
                    <table
                      class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
                    >
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Name</th>
                          <th scope="col">Code</th>
                          <th scope="col">Date</th>
                          <th scope="col">Can combined</th>
                          <th scope="col">Discount Amount</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-for="(coupon, i) in coupons">
                          <tr :key="i">
                            <td scope="row">
                              <span
                                class="text-dark-75 font-weight-bolder d-block font-size-lg"
                                >#{{ coupon.id }}</span
                              >
                            </td>
                            <td>
                              <p
                                class="text-dark-75 font-weight-bolder d-block font-size-lg"
                              >
                                {{ coupon.name }}
                              </p>
                            </td>
                            <td>
                              <p
                                class="text-dark-75 font-weight-bolder d-block font-size-lg"
                              >
                                {{ coupon.code }}
                              </p>
                            </td>
                            <td>
                              <p>
                                <span
                                  class="text-dark-75 font-weight-bolder font-size-lg"
                                  >{{ coupon.from_date }}</span
                                >
                                <v-icon class="ml-2"
                                  >mdi-arrow-right-bold-outline</v-icon
                                >
                                <span
                                  class="ml-2 text-dark-75 font-weight-bolder font-size-lg"
                                  >{{ coupon.to_date }}</span
                                >
                              </p>
                            </td>
                            <td>
                              <p
                                class="text-dark-75 font-weight-bolder d-block font-size-lg light-blue--text text--lighten-1"
                                v-if="coupon.can_combined === 1"
                              >
                                Có thể kết hợp ct giảm khác
                              </p>
                              <p
                                class="text-dark-75 font-weight-bolder d-block font-size-lg cyan--text text--lighten-1"
                                v-if="coupon.can_combined === 0"
                              >
                                Không thể kết hợp ct giảm khác
                              </p>
                            </td>
                            <td>
                              <p
                                class="text-dark-75 font-weight-bolder d-block font-size-lg pt-4 blue--text text--lighten-1"
                                v-if="coupon.discount_type === 1"
                              >
                                {{ coupon.discount_amount | formatNumber }} VND
                              </p>
                              <span
                                class="label label-lg label-inline font-weight-bold py-4 label-light-warning"
                                v-if="coupon.discount_type === 2"
                                >{{ coupon.discount_amount }} %</span
                              >
                            </td>
                            <td>
                              <span
                                style="width: 137px"
                                v-if="coupon.status === 1"
                                ><span
                                  class="label label-success label-dot mr-2"
                                ></span
                                ><span class="font-weight-bold text-success"
                                  >Active</span
                                ></span
                              >
                              <span
                                style="width: 137px"
                                v-if="coupon.status === 2"
                                ><span
                                  class="label label-warning label-dot mr-2"
                                ></span
                                ><span class="font-weight-bold text-primary"
                                  >Expired</span
                                ></span
                              >
                              <span
                                style="width: 137px"
                                v-if="coupon.status === 3"
                                ><span
                                  class="label label-warning label-dot mr-2"
                                ></span
                                ><span class="font-weight-bold text-warning"
                                  >Archived</span
                                ></span
                              >
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogCouponList = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "CouponList",
  props: {
    show_dialog: {
      type: Boolean,
      default: false,
    },
    coupons: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    dialogCouponList: {
      get() {
        return this.show_dialog;
      },
      set(val) {
        this.$emit("setDialog", val);
      },
    },
  },
};
</script>

<style scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
.table td {
  vertical-align: middle;
}
</style>
